:root {
  --primary-title-color: #727272;
  --second-title-color: #f8bb10;
  --primary-bg-color: #fff;
  --primary-black-color: #fbfbfb;
  --second-bg-color: #fcfcfc;
  --second-black-color: #f8f8f8;
  --third-black-color: #ececec;
  --primary-bg-color-opacity: #ffffffcc;
  --primary-text-color: #939393;
  --second-text-color: #000;
  --nav-col-text-color: #333;
  --nav-col-text-hover-color: #177cc4;
  --phone-top-nav-col-line-color: #444;
  --phone-top-nav-col-title-color: #444;
  --phone-top-nav-col-text-color: #444;
  --primary-burger-icon-bg-color: #444;
  --second-burger-icon-bg-color: #fff;
  --subject-blue: #177cc4;
  --navBar-background-fixed-bg-color: linear-gradient(
    to left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.05) 35%,
    rgba(0, 0, 0, 0.24) 100%
  );
  --select-color: #fff;
  --select-bg-color: #666;
}
