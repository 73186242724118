@import "../../theme";
.container {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
}
.container_contain {
  width: 100%;
  @media (min-width: 1024px) {
    display: flex;
    width: 1276px;
  }
}
.container_bg_primaryHeader {
  position: absolute;
  height: 100%;
  width: 50%;
  z-index: -1;
  left: 0;
  background-color: var(--primary-black-color);
  @media (max-width: 1024px) {
    display: none;
  }
}
.container_bg_primaryContent {
  position: absolute;
  height: 100%;
  width: 50%;
  z-index: -1;
  right: 0;
  background-color: var(--primary-bg-color);
  @media (max-width: 1024px) {
    display: none;
  }
}
.container_bg_secondHeader {
  position: absolute;
  height: 100%;
  width: 50%;
  z-index: -1;
  left: 0;
  background-color: var(--second-black-color);
  @media (max-width: 1024px) {
    display: none;
  }
}
.container_bg_secondContent {
  position: absolute;
  height: 100%;
  width: 50%;
  z-index: -1;
  right: 0;
  background-color: var(--second-bg-color);
  @media (max-width: 1024px) {
    display: none;
  }
}
.titleSection {
  display: flex;
  background: var(--primary-black-color);
  padding: 2.45rem 2.45rem 2.45rem;
  @media (min-width: 760px) {
    padding: 3.15rem 3.5rem 3.15rem;
  }
  @media (min-width: 1024px) {
    background: var(--primary-black-color);
    width: 30%;
  }
  @media (min-width: 1200px) {
    padding: 4.375rem 5.25rem 1.6625rem;
  }
}
.titleSection_container {
  height: 100%;
  width: 100%;
  text-align: right;
  @media (max-width: 1024px) {
    width: 100%;
    text-align: left;
  }
}
.titleSection_container_title {
  color: var(--primary-title-color);
  text-transform: uppercase;
  font-size: 1.68rem;
  font-weight: 600;
  margin: 0 0 10px 0;
}
.titleSection_container_subtitle {
  color: var(--primary-text-color);
  font-size: 1.4rem;
}
.infoSection {
  width: 100%;
  background-color: var(--primary-bg-color);
  padding: 2.45rem;
  @media (min-width: 760px) {
    padding: 0 3.5rem 3.15rem;
  }
  @media (min-width: 1024px) {
    width: 70%;
    background-color: var(--primary-bg-color);
  }
}
.infoSection_container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 760px) {
  }
  @media (min-width: 1024px) {
    position: relative;
    flex: 0 50%;
  }
  @media (min-width: 600px) {
    :last-child {
      padding-bottom: 0;
    }
  }
}
.infoSection_container_timeLine {
  border-left: 2px solid var(--third-black-color);
  margin-left: calc(1rem - 2px);
  padding-left: 25px;
  position: relative;
  padding-top: 3.15rem;
}
.infoSection_container_timeLine_end {
  border: 2px solid var(--third-black-color);
  height: 1px;
  width: 16px;
  position: absolute;
  left: -9px;
  bottom: 0;
  border-width: 1px;
  border-style: solid;
}
