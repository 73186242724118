@import "../../../theme";
.container {
  height: 100%;
}
.navContainer {
  height: 100%;
  display: flex;
}
.navBar {
  width: 25rem;
  @media only screen and (max-width: 1024px) {
    display: flex;
    position: fixed;
    width: 100%;
    z-index: 1;
  }
  @media only screen and (min-width: 1024px) {
    z-index: 1;
    background-color: var(--primary-bg-color);
    left: 0%;
  }
}
.navBar-open {
  left: 0%;
  height: 100%;
  transition: 0.3s;
}
.navBar-close {
  @media only screen and (max-width: 1024px) {
    left: -100%;
    transition: 0.3s;
  }
}
.navBar_menuNavLayer {
  width: 25rem;
  background-color: var(--primary-bg-color);
}
.navBar_siteTitle {
  display: flex;
  justify-content: center;
  color: var(primary-title-color);
  text-decoration: none;
  font-size: 2rem;
  font-weight: 500;
  padding: 30px;
}
.navBar_menuNav {
  width: 220px;
  height: 100%;
  background-color: #ffffff;
  padding: 16px;
}
.navBar_menuNav_item {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  text-align: center;
  flex-wrap: row wrap;
}

.navBar_menuNav_item_title {
  display: flex;
  justify-content: center;
  width: 100%;
  color: var(primary-title-color);
  font-size: 1.9rem;
  font-weight: 600;
  transition: 0.5s;
  padding: 10px 0;
  &:hover {
    color: var(--second-title-color);
    transition: 0.5s;
  }
}
.background {
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.05) 35%,
    rgba(0, 0, 0, 0.24) 100%
  );
  @media only screen and (min-width: 1024px) {
    display: none;
  }
}
