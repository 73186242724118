@import "../../../theme";
.container {
  display: flex;
  position: relative;
  width: 100%;
  line-height: 1.5;
  padding: 0 0 50px 0;
  @media (max-width: 600px) {
    width: 100%;
  }
  @media (min-width: 760px) {
  }
}
.container_point {
  position: absolute;
  background: var(--second-title-color);
  box-shadow: 0 0 0 6px #fff;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  left: -31px;
}
.container_item_title {
  width: 50%;
  padding: 0 20px 0 0;
}
.container_item_title_period {
  font-size: 1.2rem;
  color: var(--primary-text-color);
  margin: 0 0 5px 0;
}
.container_item_title_title {
  font-size: 1.68rem;
  color: var(--primary-title-color);
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: underline;
  margin: 0 0 6px 0;
  transition: 0.5s;
  &:hover {
    color: var(--second-title-color);
    text-decoration: underline;
    transition: 0.5s;
  }
}
.container_item_title_position {
  font-size: 1.2rem;
  color: var(--primary-title-color);
  margin: 0 0 6px 0;
}
.container_item_title_location {
  font-size: 1.2rem;
  color: var(--primary-text-color);
}
.container_info {
  font-size: 1.4rem;
  color: var(--primary-text-color);
  width: 60%;
}
